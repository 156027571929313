import React from "react";
import InnerPageComponent from "../../components/InnerPageComponent/InnerPageComponent";
import PassengerNotificationComponent from "../../components/PassengerNotifications/PassengerNotifications";

const PassengerNotificationsContainer = () => {
    return(
        <>
            <InnerPageComponent>
                <PassengerNotificationComponent/>
            </InnerPageComponent>
        </>
    )
};

export default PassengerNotificationsContainer